@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

h2 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.hover-lighten:hover {
  filter: brightness(80%);
}

.cardgrid {
  align-items: center;
}

.navbar {
  background-color: #7f95d1;
}
.profile-logo {
  font-size: 1.25rem;
  padding-top: 10px;
  color: #333;
  background-color: #ddd6fe;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  backdrop-filter: blur(1px);
}

.site-main-blur {
  filter: blur(5px);
}

.popup-container {
  z-index: 99;
  position: absolute;
  width: 500px;
  height: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: none;
  border-radius: 20px;
  border-color: black;
}

.popup {
  background-color: white;
  padding: 20px;
  height: 300px;
  border-radius: 5px;
  border-width: 3px;
  box-shadow: #111;
}

.close-popup {
  position: absolute;
  top: 2%;
  left: 92%;
  text-align: right;
  font-family: Arial, Helvetica, sans-serif;
  color: #555;
  font-weight: 700;
  font-size: xx-large;
}

.close-popup:hover {
  color: black;
}

.faq-box {
  margin: 2rem;
  padding: 1rem;
  border-radius: 1rem;

  /* border-width: 1px; */
}

.accordion-header {
  background-color: #e1f2ff;
  border-radius: 0.25rem;
  padding: 1rem;
  border-width: 1px;
  color: #2d81f6;
  font-weight: normal;
  font-size: large;
  font-family: Arial, Helvetica, sans-serif;
}

.accordion-body {
  padding: 1rem;
  border-width: 1px;
  border-top: 0;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: blur(5px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: center;
  justify-content: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #999;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  width: 60px;
}

.react-confirm-alert-button-group > button:hover {
  background: #111;
}

.site-main-popup {
  filter: blur(1px);
}

.classic-button {
  border: #1f2937;
  border-width: 1px;
  border-radius: 0.5rem;
}


.weight-chart-page {
  margin: 1rem;
  padding: 1rem;
  /* border-radius: 1rem;
  border-width: 1px; */
}

.about-page{
   width:100%;
   max-width: 800px;
   margin: 10px auto;
  text-align:justify;
}
.about-content{
   width: 100%;
   max-width: 800px;
   height: auto;
   color: #555;
}
.about-header{
  font-weight: bold;
  padding-top: 2rem;
}
.about-content{
  padding-top: 0.75rem;
}
.about-content-container {
  text-align: justify;
}

.ServiceCard {
  width: 200px;
  height: 200px;
  padding: 10px;
  margin: 10px;
}

.ServiceCardContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.ServiceCardButton {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 55%;
}

.ServiceCardGrid {
  margin: 10px;
  width: 100%;
}

.info-banner {
    background-color: #AC87C5;
    padding: 1rem;
    color: white;
    min-height: 200px;
}

footer {
  display: flex;
  background-color: #FFE5E5;
  padding: 1rem;
  min-height: 100px;
}


.compatible-with-strava-logo {
  height: 50px;
  position: absolute;
  right: 0;
}

.works-with-fitbit-logo {
  height: 50px;
  position: absolute;
  left: 20px;
}


.two-col-home {
    min-height: 250px;
  }

@media (max-width: 1024px) {
  .ServiceCardGrid {
    width: calc(75% - 10px);
  }
}

@media (max-width: 768px) {
  .ServiceCardGrid {
    width: 100%;
  }
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
